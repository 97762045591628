<template>
	<div>
		<div class="screen-title">お問い合わせ</div>
		<b-container fluid>
			<b-row class="w-100 mx-0">
				<validation-observer
					class="w-100"
					v-slot="{ handleSubmit }"
					ref="formValidator"
				>
					<b-form role="form" @submit.prevent="handleSubmit(onSubmitInquiryForm)">
						<card class="my-0 w-100 d-flex justify-content-center">
							<div class="note d-flex flex-column justify-content-start mb-4">
								<span
									>本システムに関することを、運営事務局にお問い合わせいただけます。</span
								>
								<span>３営業日以内に指定されたメールアドレスに返信いたします。</span>
							</div>
							<b-row class="custom__mx-3 pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										class="mb-3"
										placeholder="お名前"
										name="お名前"
										v-model="model.name"
										:rules="{ required: true }"
										label="お名前"
										:disabled="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										alternative
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
										class="mb-3"
										placeholder="メールアドレス"
										name="メールアドレス"
										v-model="model.mailAddress"
										:rules="{ required: true, email: true }"
										label="メールアドレス"
										type="email"
										:inputRequired="true"
									>
									</base-input>
								</div>
							</b-row>
							<b-row class="custom__mx-3 pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										class="mb-3"
										placeholder="電話番号"
										name="電話番号"
										v-model="model.telephoneNumber"
										:rules="{ required: true }"
										label="電話番号"
										type="text"
										@keydown="onKeyDown"
										@blur="checkPhoneNumber"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md col-xs-12 px-0">
									<base-input
										:rules="{ required: true }"
										label="お問い合わせ種別"
										name="お問い合わせ種別"
										:inputRequired="true"
										:labelClasses="'custom-form-input-label'"
									>
										<el-select
											placeholder="選択なし"
											v-model="inquiryCategorySelect"
											filterable
											clearable
										>
											<el-option
												v-for="option in inquiryCategoryOptions"
												:key="option.value"
												:label="option.label"
												:value="option.label"
											>
											</el-option>
										</el-select>
									</base-input>
								</div>
							</b-row>
							<b-row class="custom__mx-3 d-flex flex-column">
								<div class="elm-title" style="font-size: 14px; margin-bottom: 7px">
									詳細
								</div>
								<textarea
									class="form-control"
									type="text"
									v-model="model.inquiryContent"
									style="resize: none"
									rows="5"
								></textarea>
							</b-row>
						</card>
						<b-row class="mx-0 px-0 my-4 justify-content-center">
							<button
								type="primary"
								class="customButton customButton__submit"
								native-type="submit"
							>
								送信
							</button>
						</b-row>
					</b-form>
				</validation-observer>
			</b-row>
		</b-container>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			:single-btn="notiSingleBtn"
			@on-click="onAccept"
		/>
	</div>
</template>
<script>
import { Select, Option } from 'element-ui';
import Mail from '../../services/mail.service';
import { mapState } from 'vuex';
import InquiryService from '../../services/inquiry.service';
import ManagementService from '../../services/managementmaster.service';
import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';

export default {
	name: 'InquiryForm',
	components: {
		[Select.name]: Select,
		[Option.name]: Option,
		NotiModal,
	},
	computed: {
		// Get projectId, userId from store
		...mapState(['userInfo']),
	},
	data() {
		return {
			model: {
				name: '',
				mailAddress: '',
				telephoneNumber: '',
				inquiryContent: '',
			},
			inquiryCategorySelect: '',
			inquiryCategoryOptions: [],
			managementMaster: {},
			notiModalId: 'inquiry-noti',
			notiContent: '',
			notiSingleBtn: '',
		};
	},
	methods: {
		checkPhoneNumber() {
			if (this.model.telephoneNumber) {
				this.model.telephoneNumber = this.model.telephoneNumber.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		onSubmitInquiryForm() {
			this.notiContent = 'お問い合わせを送信します。\nよろしいですか？';
			this.notiSingleBtn = false;
			this.$bvModal.show(`${this.notiModalId}`);
		},

		onAccept() {
			this._sendMail();
		},

		async _sendMail() {
			let recivedMailAddress = [
				this.managementMaster.inquiryMail_1,
				this.managementMaster.inquiryMail_2,
				this.managementMaster.inquiryMail_3,
			];
			let destinations = [];
			recivedMailAddress.map((mail) => {
				if (mail !== '') {
					destinations.push(mail);
				}
			});
			let mailContents = {
				destinations: [...destinations],
				sender: this.managementMaster.fromMailAddress,
				subject: '【COMODE】お問い合わせへの対応のお願い。',
				template: 'inquiry',
				template_params: {
					name: this.model.name,
					mail: this.model.mailAddress,
					telephone: this.model.telephoneNumber,
					inquiryType: this.inquiryCategorySelect,
					inquiryContent: this.model.inquiryContent,
					managerCompanyName: this.managementMaster.companyName,
					managerMail: this.managementMaster.masterMail,
				},
			};

			let responseSendInquiry = await Mail.sendMail(mailContents);

			if (responseSendInquiry.status === 200) {
				let mailContents = {
					destinations: [this.model.mailAddress],
					sender: this.managementMaster.fromMailAddress,
					subject: '【COMODE】お問い合わせの送信を完了しました。',
					template: 'inquiry_received',
					template_params: {
						name: this.model.name,
						mail: this.model.mailAddress,
						telephone: this.model.telephoneNumber,
						inquiryType: this.inquiryCategorySelect,
						inquiryContent: this.model.inquiryContent,
						managerCompanyName: this.managementMaster.companyName,
						managerMail: this.managementMaster.masterMail,
					},
				};
				try {
					let responseReceivedInquiry = await Mail.sendMail(mailContents);
					console.log(responseReceivedInquiry);
					this.notiContent = 'お問い合わせの送信を完了しました。';
					this.notiSingleBtn = 'true';
					this.$bvModal.show(`${this.notiModalId}`);
				} catch (error) {
					throw error;
				}
				this.clearForm();
			}
		},
		async createInquiryCategoryOptions() {
			let [response] = await InquiryService.getAll();
			let inquiryCategoryOptions = [];
			if (response && response.length > 0) {
				response.map((item) => {
					let optionInfo = {
						value: item.id,
						label: item.content,
					};
					inquiryCategoryOptions.push(optionInfo);
				});
			}
			this.inquiryCategoryOptions = [...inquiryCategoryOptions];
		},
		async getManagementMasterInfo() {
			let [response] = await ManagementService.getAll();
			if (response) {
				let nullValueKeys = Object.keys(response[0]).filter(
					(key) => response[0][key] === null
				);
				nullValueKeys.map((key) => {
					response[0][key] = '';
				});
				let managementInfo = {
					companyName: response[0].company_name,
					masterMail: response[0].footer_email_address,
					fromMailAddress: response[0].sender_email_address,
					inquiryMail_1: response[0].contact_email_1,
					inquiryMail_2: response[0].contact_email_2,
					inquiryMail_3: response[0].contact_email_3,
				};
				this.managementMaster = { ...managementInfo };
			}
		},

		clearForm() {
			this.model.inquiryContent = '';
		},
	},
	async mounted() {
		console.log(this.userInfo);
		this.model.name = this.userInfo['register_name'];
		this.model.mailAddress = this.userInfo['email_address'];
		this.model.telephoneNumber = this.userInfo['phone_number'];
		await this.getManagementMasterInfo();
		await this.createInquiryCategoryOptions();
	},
};
</script>
