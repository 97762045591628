import ApiService from './api.service';

const serviceURL = 'inquiry';
const InquiryService = {
	getAll() {
		return ApiService.get(`${serviceURL}/`);
	},

	create_new_inquiry(contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.post(`${serviceURL}/`, body);
	},
};

export default InquiryService;
