import ApiService from './api.service';

const serviceURL = 'management_master';
const ManagementService = {
	getAll() {
		return ApiService.get(`${serviceURL}/`);
	},

	/**
	 * Update management content from text box
	 * @param {Integer} managementMasterId: Project ID
	 * @param {Object} contents: Project contents for updating
	 * @returns
	 */
	update(managementMasterId, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${managementMasterId}`, body);
	},
};

export default ManagementService;
